import { Pagination, Table } from 'antd';
import React, { useState, useRef, Fragment } from 'react'
import "./style.scss";
import moment from 'moment';
import { Button } from 'antd';
import InputBoxModal from '../inputbox-modal/inputbox-modal';
import { updatePartnerPassword } from '../../services/put';
import { message } from 'antd';

const ClientsTable = ({clients}) => {
      const [currentPage, setCurrentPage] = useState(1);
      const [pageSize, setPageSize] = useState(15);
      const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
      const [selectedClient, setSelectedClient] = useState(null);
      const passwordRef = useRef(null);
      const handlePaginationChange = (page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        };  

     const columns = [
        { 
          title: "Client Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Client Type",
          width: 200,
          dataIndex: "client_type",
          key: "client_type",
        },
        {
          title: "Email Id",
          dataIndex: "email",
          key: "email",
          render: (_,record) => {
            if(record.users && record.users.length > 0){
              return record.users[0].email;
            }
            return "N/A";
          }
        },
        {
          title: "Mobile NO.",
          render: (_,record) => {
            if(record.phones && record.phones.length > 0){
              return record.phones[0].number;
            }
            return "N/A";
          }
        },
        {
          title: "City",
          dataIndex: "city",
          key: "city",
          render: (_,record) => {
            if(record.addresses && record.addresses.length > 0){
              return record.addresses[0].city;
            }
            return "N/A";
          }
        },
        {
          title: " Launch Date",
          dataIndex: "launch_date",
          key: "launch_date",
          render: (_,record) => {
            if(record.launch_date){
              return moment(record.launch_date).format("DD-MM-YYYY");
            }
            return "N/A";
          }
        },
        { title: "Action", dataIndex: "", key: "action", width: 250,
          render: (_,record) => {
            return <Button onClick={() => handleUpdatePassword(record)}>Update Password</Button>;
          }
       },
      ];

    const handleUpdatePassword = (record) => {
      setUpdatePasswordModal(true);
      setSelectedClient(record);
    }

    const handleUpdatePasswordSubmit = (value) => {
      console.log(selectedClient);
      console.log(value);
      setUpdatePasswordModal(false);
      updatePartnerPassword(selectedClient.id, { password: value })
      .then((res) => {
        console.log(res);
        message.success("Password updated successfully");
      })
      .catch((err) => {
        console.log(err);
        message.error("Failed to update password");
        setUpdatePasswordModal(false);
      })
    }

  return (
    <Fragment>

    <InputBoxModal
      visible={updatePasswordModal}
      onCancel={() => setUpdatePasswordModal(false)}
      onSubmit={handleUpdatePasswordSubmit}
      title="Update Password"
    />
    <div className='all-client-container flex-1 flex flex-column'>
       <Table
        columns={columns}
        dataSource={clients? clients : []}
        pagination={false}
        rowKey="id"
        className="client-table"
        sticky
        scroll={{ x: 'max-content' }}
        loading={clients === null}
        // onChange={handleTableChange}
      />
      <div className="pagination-section flex align-items-center pt-3">
        <Pagination
          align="end"
          current={currentPage}
          pageSize={pageSize}
        //   total={getFilteredData().length}
          onChange={handlePaginationChange}
          className="pagination"
          showSizeChanger={false}
        />
      </div>
    </div>
    </Fragment>
  )
}

export default ClientsTable

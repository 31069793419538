import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import LoginPage from "../Pages/LoginPage/LoginPage";
import NotFound from "../Pages/NotFoundPage/NotFound";
import { CustomLoader } from "../CommonComponents/loader/Loader";
import PartnerManagement from "../Pages/partnerManagement/PartnerManagement";
import AllPartner from "../Pages/allPartner/AllPartner";

// Customer Pages
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const OrderTrackingPage = lazy(() =>
  import("../Pages/OrderTrackingPage/OrderTrackingPage")
);
const OrderPage = lazy(() => import("../Pages/OrderPage/OrderPage"));
const OrderDetailPage = lazy(() =>
  import("../Pages/OrderDetailPage/OrderDetailPage")
);
const CartPage = lazy(() => import("../Pages/CartPage/CartPage"));
const AddressPage = lazy(() => import("../Pages/AddressPage/AddressPage"));
const AddressFormPage = lazy(() =>
  import("../Pages/AddressFormPage/AddressFormPage")
);
const WalletPage = lazy(() => import("../Pages/WalletPage/WalletPage"));
const OrderSuccessPage = lazy(() =>
  import("../Pages/OrderSuccessPage/OrderSuccessPage")
);
const OrderFailurePage = lazy(() =>
  import("../Pages/OrderFailurePage/OrderFailurePage")
);
const Policy = lazy(() => import("../Pages/Policy/Policy"));
const Contact = lazy(() => import("../Pages/Contact/Contact"));

// Store Pages
const AdminDashboard = lazy(() =>
  import("../Pages/adminDashboard/AdminDashBoard")
);
const OrderManagement = lazy(() =>
  import("../Pages/orderManagement/OrderManagement")
);
const KitchenManagement = lazy(() =>
  import("../Pages/kitchenManagement/KitchenManagement")
);

const TallyAtOnce = lazy(() => 
import("../Pages/TallyAtOnce/TallyAtOnce")
);

const ActiveOrders = lazy(() => import("../Pages/activeOrders/ActiveOrders"));
const AllOrders = lazy(() => import("../Pages/allOrders/AllOrders"));
const StockManagement = lazy(() => import("../Pages/stockManagement/StockManagement"));

// Private Route component
const PrivateRoute = ({ children, allowedPartnerTypes }) => {
  const { authToken, partnerType } = useContext(AuthContext);

  if (!authToken) return <Navigate to="/login" replace />;
  // if (!allowedPartnerTypes.includes(partnerType))
  //   return <Navigate to="/not-found" replace />;
  return children;
};

// Main Router component
const Routers = () => {
  const location = useLocation();
  const { authToken, partnerType } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Suspense fallback={<CustomLoader />}>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        {/* Customer Routes */}
        {[
          { path: "/customer-dashboard", element: <Dashboard /> },
          { path: "/orders", element: <OrderTrackingPage /> },
          { path: "/order-page", element: <OrderPage /> },
          { path: "/order-detail/:orderId", element: <OrderDetailPage /> },
          { path: "/cart-page", element: <CartPage /> },
          { path: "/address-page", element: <AddressPage /> },
          { path: "/add-address-page", element: <AddressFormPage /> },
          { path: "/wallet-page", element: <WalletPage /> },
          { path: "/order-success", element: <OrderSuccessPage /> },
          { path: "/order-failure", element: <OrderFailurePage /> },
          { path: "/policy", element: <Policy /> },
          { path: "/contactUs", element: <Contact /> },
        ].map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute allowedPartnerTypes={["customer", "admin"]}>
                {element}
              </PrivateRoute>
            }
          />
        ))}

        {/* Store Routes */}
        <Route
          path="/admin-dashboard"
          element={
            <PrivateRoute allowedPartnerTypes={["admin"]}>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
         <Route
          path="/all-orders-tally"
          element={
            <PrivateRoute allowedPartnerTypes={["admin"]}>
              <TallyAtOnce />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-management"
          element={
            <PrivateRoute allowedPartnerTypes={["admin"]}>
              <OrderManagement />
            </PrivateRoute>
          }
        >
          {/* Nested Routes for Active and All Orders */}
          <Route path="active-orders" element={<ActiveOrders />} />
          <Route path="all-orders" element={<AllOrders />} />
        </Route>

        <Route
          path="/kitchen-management"
          element={
            <PrivateRoute allowedPartnerTypes={["admin"]}>
              <KitchenManagement />
            </PrivateRoute>
          }
        >
          <Route path="stock-management" element={<StockManagement />} />
        </Route>

        <Route
          path="/partner-management"
          element={
            <PrivateRoute allowedPartnerTypes={["admin"]}>
              <PartnerManagement />
            </PrivateRoute>
          }
        >
          <Route path="all-partner" element={<AllPartner />} />
        </Route>

        {/* Redirect based on partner type */}
        <Route
          path="/"
          element={
            authToken ? (
              partnerType === "customer" ? (
                <Navigate to="/customer-dashboard" />
              ) : (
                <Navigate to="/admin-dashboard" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
    </Suspense>
  );
};

export default Routers;
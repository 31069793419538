// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-section .top-section .left-side .main-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/Pages/allPartner/style.scss"],"names":[],"mappings":"AAGQ;EACN,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAFF","sourcesContent":[".main-section{\n    .top-section{\n      .left-side{\n        .main-title{\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 30px;\n  color: #000000;\n        }\n      }}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

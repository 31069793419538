// import React from "react";
// import "./style.scss";
// import { DatePicker, Form, Input, Select } from "antd";

// const AddPartner = ({ form }) => {
//   const onFinish = (values) => {
//     console.log("Form Submitted: ", values); // Logs the form values to the console
//   };

//   return (
//     <Form
//     className="add-partner-form"
//       form={form}
//       layout="vertical"
//       onFinish={onFinish}
//       initialValues={{
//         name: "",
//         contactNumber: "",
//         email: "",
//         password: "",
//       }}
//     >
//      <div className="basic-information">
//         <div className="title">Basic Information</div>
//      <div className="flex align-items-center justify-content-between gap-2">
//      <Form.Item
//         label="Name"
//         name="name"
//         rules={[{ required: true, message: "Please enter the name" }]}
//       >
//         <Input placeholder="Enter name" />
//       </Form.Item>

//       <Form.Item
//         label="Contact No."
//         name="contactNumber"
//         rules={[
//           { required: true, message: "Please enter the contact number" },
//           { pattern: /^[0-9]+$/, message: "Contact number must be numeric" },
//         ]}
//       >
//         <Input placeholder="Enter contact number" />
//       </Form.Item>
//       <Form.Item label="Select">
//         <Select  placeholder="Select">
//           <Select.Option value="demo">Demo</Select.Option>
//         </Select>
//       </Form.Item>
//      </div>

//      <Form.Item
//         label="DatePicker"
//         name="DatePicker"
//         rules={[
//           {
//             required: true,
//             message: 'Please input!',
//           },
//         ]}
//       >
//         <DatePicker />
//       </Form.Item>
//      </div>
//      <div className="address-information">
//      <div className="title">Address</div>
//      <div className="flex align-items-center justify-content-between gap-2">
//      <Form.Item
//         label="Flat, House no., Building, Company"
//         name="Address-1"
//         rules={[{ required: true, message: "Please enter the name" }]}
//       >
//         <Input placeholder="Enter" />
//       </Form.Item>
//       <Form.Item
//         label="Area, street, Sector, Village"
//         name="Address-2"
//         rules={[{ required: true, message: "Please enter the name" }]}
//       >
//         <Input placeholder="Enter" />
//       </Form.Item>
//         </div>
//         <div className="flex align-items-center justify-content-between gap-2">
//      <Form.Item
//         label="Pincode"
//         name="pincode"
//       >
//         <Input placeholder="Enter" />
//       </Form.Item>
//       <Form.Item
//         label="Town/city"
//         name="city"
//       >
//         <Input placeholder="Enter" />
//       </Form.Item>
//       <Form.Item
//         label="State"
//         name="state"
//       >
//         <Input placeholder="Enter" />
//       </Form.Item>
//         </div>
//      </div>

//      <div className="address-information">
//      <div className="title">App Login Credentials</div>
//      <div className="flex align-items-center justify-content-between gap-2">
//      <Form.Item
//         label="Email ID."
//         name="email"
//         rules={[
//           { required: true, message: "Please enter the email" },
//           { type: "email", message: "Please enter a valid email" },
//         ]}
//       >
//         <Input placeholder="Enter email" />
//       </Form.Item>
//       <Form.Item
//         label="Password"
//         name="password"
//         rules={[{ required: true, message: "Please enter the password" }]}
//       >
//         <Input.Password placeholder="Enter password" />
//       </Form.Item>
//       <div className="flex-1 "></div>
//         </div>

//      </div>
//     </Form>
//   );
// };

// export default AddPartner;



import React, { useState } from "react";
import "./style.scss";
import { DatePicker, Form, Input, Select } from "antd";
import moment from "moment"; 

const AddPartner = ({ form, onFinish, loading }) => {
  return (
    <Form
      className="add-partner-form"
      form={form}
      layout="vertical"
      onFinish={onFinish}
      loading={loading}
      initialValues={{
        name: "",
        contactNumber: "",
        email: "",
        password: "",
      }}
    >
      {/* Basic Information Section */}
      <div className="basic-information">
        <div className="title">Basic Information</div>
        <div className="flex align-items-center justify-content-between gap-2">
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter the name" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>

          <Form.Item
            label="Contact No."
            name="phone"
            rules={[
              { required: true, message: "Please enter the contact number" },
              { pattern: /^[0-9]+$/, message: "Contact number must be numeric" },
            ]}
          >
            <Input placeholder="Enter contact number" />
          </Form.Item>

          <Form.Item
            label="Select"
            name="client_type"
            rules={[{ required: true, message: "Please select a type" }]}
          >
            <Select placeholder="Select">
              <Select.Option value="B2B">B2B</Select.Option>
              <Select.Option value="Franchise">Franchise</Select.Option>
              <Select.Option value="qsr">QSR</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          label="Date of Launch"
          name="launch_date"
          rules={[
            {
              required: true,
              message: "Please select the launch date",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
      </div>

      {/* Address Information Section */}
      <div className="address-information">
        <div className="title">Address</div>
        <div className="flex align-items-center justify-content-between gap-2">
          <Form.Item
            label="Flat, House no., Building, Company"
            name="address_line_1"
            rules={[{ required: true, message: "Please enter the address" }]}
          >
            <Input placeholder="Enter" />
          </Form.Item>

          <Form.Item
            label="Area, Street, Sector, Village"
            name="address_line_2"
            rules={[{ required: true, message: "Please enter the address" }]}
          >
            <Input placeholder="Enter" />
          </Form.Item>
        </div>
        <div className="flex align-items-center justify-content-between gap-2">
          <Form.Item label="Pincode" name="pincode">
            <Input placeholder="Enter" />
          </Form.Item>

          <Form.Item label="Town/City" name="city">
            <Input placeholder="Enter" />
          </Form.Item>

          <Form.Item label="Landmark" name="landmark">
            <Input placeholder="Enter" />
          </Form.Item>
        </div>
      </div>

      {/* App Login Credentials Section */}
      <div className="login-credentials">
        <div className="title">App Login Credentials</div>
        <div className="flex align-items-center justify-content-between gap-2">
          <Form.Item
            label="Email ID"
            name="avoid_auto_fill_email"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            rules={[
              { required: true, message: "Please enter the email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="avoid_auto_fill_password"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            rules={[{ required: true, message: "Please enter the password" }]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>

          <div className="flex-1"></div>
        </div>
      </div>
    </Form>
  );
};

export default AddPartner;

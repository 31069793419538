import React, { useState } from "react";
import { Modal, Input, Button, Divider } from "antd";
import "./inputbox-modal.scss";

const InputBoxModal = ({ visible, title, onSubmit, onCancel }) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!inputValue.trim()) {
      setError("This field cannot be empty");
      return;
    }
    onSubmit(inputValue);
    setInputValue("");
    setError("");
  };

  const handleCancel = () => {
    setInputValue("");
    setError("");
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      centered
      className="input-box-modal"
    >
      <div className="flex flex-column">
        <div className="p-4 flex flex-column gap-2">
          <div className="modal-title flex justify-content-center">
            {title}
          </div>
          <Input
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setError("");
            }}
            status={error ? "error" : ""}
          />
          {error && <div className="error-message">{error}</div>}
        </div>
        <Divider />
        <div className="modal-actions flex justify-content-end gap-2 p-4">
          <Button className="SecondaryBtn" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="primaryBtn" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InputBoxModal;

import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const CustomBreadcrumb = ({ setTitle }) => {
    const location = useLocation();

    // Breadcrumb mapping
    const breadcrumbNameMap = {
        "/": "Dashboard",
        "/order-management": "Order Management",
        "/order-management/active-orders": "Active Orders",
        "/order-management/all-orders": "All Orders",
        "/partner-management": "Partner Management",
        "/partner-management/all-partner": "All Partners",
        "/kitchen-management": "Kitchen Management",
        "/kitchen-management/stock-management": "Stock Management",
        "/kitchen-management/inventory": "Inventory",
        "/kitchen-management/employee": "Employee",
        "/dashboard": "Dashboard",
        "/stock": "Stocks",
        "/setting": "Settings",
        "/help-center": "Help Center",
        "/all-orders-tally": "Tally At Once"
    };

    const pathSnippets = location.pathname.split("/").filter(i => i);

    // Generate breadcrumb items from path snippets
    const breadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
        
        // Set the title to the last breadcrumb item
        if (index === pathSnippets.length - 1) {
            setTitle(breadcrumbNameMap[url] || "");
        }

        // Non-clickable breadcrumbs
        if (url === '/order-management' || url === '/kitchen-management' || url === '/partner-management') {
            return (
                <Breadcrumb.Item key={url}>
                    {breadcrumbNameMap[url]}
                </Breadcrumb.Item>
            );
        }

        // Clickable breadcrumbs
        return breadcrumbNameMap[url] ? (
            <Breadcrumb.Item key={url}>
                <Link to={url}>{breadcrumbNameMap[url]}</Link>
            </Breadcrumb.Item>
        ) : null;
    });

    return (
        <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item>
                <Link to="/">Dashboard</Link>
            </Breadcrumb.Item>
            {breadcrumbItems}
        </Breadcrumb>
    );
};
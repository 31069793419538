import React, { useContext, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { CustomButton as Button } from "../CommonComponents/store/button/CustomButton";
import DynamicModal from './DynamicModal/DynamicModal';
import { OrderContext} from '../Context/OrderContext';

const ModalContainer = () => {
  const { isModalOpen, setIsModalOpen, modalTitle, modalContentComponent, modalActions , isButtonDisabled} = useContext(OrderContext);
  const toastRef = useRef(null);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    if (isModalOpen) {
      setRenderKey((prevKey) => prevKey + 1);
    }
  }, [isModalOpen, modalActions]);


  // Reusable button rendering
  const renderModalFooter = () => (
    <div className='footer-container flex align-items-center gap-2'>
     {modalActions?.map((action, index) => (
        <Button
          key={index}
          customBtnClass={action.customBtnClass}
          onClick={action.onClick}
          disabled={action.label === "Cancel" ? false : isButtonDisabled} // Dynamically disable buttons
        >
          {action.label}
        </Button>
      ))}
    </div>
  );

  return (
    <>
      <Toast ref={toastRef} />
      {isModalOpen && (
        <DynamicModal
          open={isModalOpen}
          key={renderKey}
          title={modalTitle}
          closable={true}
          close={() => setIsModalOpen(false)}
          footer={renderModalFooter()} // Render the dynamic footer
          className="custom-modal"
        >
          {modalContentComponent}
        </DynamicModal>

      )}
    </>
  );
};

export default ModalContainer;
